:root {
  --color-primary: #628799;
  --color-dark: #506e7e;
}

body {
  overscroll-behavior: none;
}

a {
  color: var(--color-primary, currentColor);
}